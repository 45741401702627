import axios from 'axios';
import routes from './routes';

const { contacts } = routes;

const ACRoutes = {
  getVoter: (selected_voterbase_id, utm_campaign, utm_source, utm_medium) =>
    axios.get(`${contacts}/public_get_voter/${selected_voterbase_id}`, {
      params: {
        utm_campaign,
        utm_medium,
        utm_source,
      },
    }),
  searchVoters: (first_name, last_name, age_range, city, state, dob) =>
    axios.post(`${contacts}/public_search_voters`, {
      age_range,
      city,
      dob,
      first_name,
      last_name,
      state,
    }),
};

export default ACRoutes;
