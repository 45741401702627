import React from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share';
import { FacebookFilled, LinkedinFilled } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import XLogoIcon from '@web/components/common/Icon/components/XLogoIcon';
import Icon from '@web/components/common/Icon';

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: black;
`;

const BottomLink = styled.a`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: black;
`;

const Link = styled.a`
  font-weight: 700;
  font-size: ${({ $isButton }) => ($isButton ? 18 : 16)};
  line-height: 22px;
  text-decoration: none;
  color: black;

  display: flex;
  align-items: center;
  gap: 5px;
  ${({ $isButton }) =>
    $isButton &&
    css`
      border: solid black 2px;
      padding: 8px 20px;
      border-radius: 10px;
    `}

  :hover {
    color: #1c1c1c;
  }
`;

const Text = styled.p`
  margin: 0;
  padding: 0;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
`;

const Flex = styled.div`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
`;

const Centered = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  flex-direction: column;
`;

const Container = styled.div`
  padding: 16px;
`;

const FooterWrapper = styled.div`
  margin-top: auto;
`;

const Footer = ({ useGetReadyToVote }) => {
  const { t } = useTranslation();

  const shareUrl = useGetReadyToVote
    ? window.location.href
    : `${window.location.origin}/my_vote_score`;

  return (
    <FooterWrapper>
      {useGetReadyToVote && (
        <>
          <Divider />
          <Container>
            <Centered>
              <Text>Want to improve your vote score? Make sure you’re ready to vote.</Text>
              <Link
                $isButton
                rel="noopener noreferrer"
                href="https://www.votehq.org/en"
                target="_blank"
              >
                {t('accelerate_change.get-ready-to-vote')}
              </Link>
              <Link href="/my_vote_score" rel="noopener noreferrer">
                {t('accelerate_change.check-another-person')} <Icon name="Refresh" />
              </Link>
            </Centered>
          </Container>
        </>
      )}
      <Divider />
      <Container>
        <Flex>
          <BottomLink href="/my_vote_score/faqs">FAQs</BottomLink>
          <Flex style={{ gap: 10 }}>
            <TwitterShareButton url={shareUrl}>
              <XLogoIcon />
            </TwitterShareButton>
            <FacebookShareButton url={shareUrl}>
              <FacebookFilled />
            </FacebookShareButton>
            <LinkedinShareButton url={shareUrl}>
              <LinkedinFilled />
            </LinkedinShareButton>
          </Flex>
        </Flex>
      </Container>
    </FooterWrapper>
  );
};

Footer.propTypes = {
  useGetReadyToVote: PropTypes.bool,
};

export default Footer;
