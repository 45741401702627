import queryString from 'query-string';
import { useLocation } from 'react-router-dom';

const useUtmParams = () => {
  const { search } = useLocation();

  const { utm_campaign, utm_medium } = queryString.parse(search);
  const utm_source = window.location.pathname.split('/')[1];

  const formattedParams = [
    utm_campaign && `utm_campaign=${utm_campaign}`,
    utm_medium && `utm_medium=${utm_medium}`,
    utm_source && `utm_source=${utm_source}`,
  ]
    .filter(Boolean)
    .join('&');

  return {
    formattedParams: formattedParams ? `?${formattedParams}` : '',
    utm_campaign,
    utm_medium,
    utm_source: window.location.pathname.split('/')[1],
  };
};

export default useUtmParams;
